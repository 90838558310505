<template>
  <v-col class="mx-1 my-3  ">
    <v-card>
      <v-row class="px-2 py-2">
        <v-spacer />

        <v-col
          cols="12"
          sm="4"
          md="3"
          lg="2"
          xl="2"
          class="  py-0 my-0 mb-2"
        >
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{on, attrs}">
              <v-text-field
                v-model="date"
                label="Tarih"
                prepend-icon="mdi-calendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                hide-details
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="date"
              no-title
              scrollable
            >
              <v-spacer />
              <Button
                :text="true"
                color="primary"
                @click="menu = false"
              >
                Kapat
              </Button>
              <Button
                :text="true"
                color="primary"
                @click="$refs.menu.save(date)"
              >
                Seç
              </Button>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          cols="12"
          sm="4"
          md="3"
          lg="2"
          xl="2"
          class="  py-0 my-0 mb-2"
        >
          <v-dialog
            ref="dialog"
            v-model="hourmodal2"
            :return-value.sync="hourmodel"
            persistent
            width="290px"
          >
            <template v-slot:activator="{on, attrs}">
              <v-text-field
                v-model="hourmodel"
                label="Saat"
                prepend-icon="mdi-clock-time-four-outline"
                readonly
                outlined
                dense
                v-bind="attrs"
                hide-details
                v-on="on"
              />
            </template>
            <v-time-picker
              v-if="hourmodal2"
              v-model="hourmodel"
              full-width
              :allowed-minutes="allowedStep"
              format="24hr"
            >
              <v-spacer />
              <Button
                :text="true"
                color="primary"
                @click="hourmodal2 = false"
              >
                Kapat
              </Button>
              <Button
                :text="true"
                color="primary"
                @click="$refs.dialog.save(hourmodel)"
              >
                Seç
              </Button>
            </v-time-picker>
          </v-dialog>
        </v-col>
        <v-col
          cols="12"
          sm="5"
          md="3"
          lg="3"
          xl="2"
          class="  py-0 my-0 mb-2 s"
        >
          <Select
            v-model="santralModel"
            :items="santralList"
            label="Santral"
            :hide-details="true"
            outlined
            dense
          />
        </v-col>

        <v-col
          cols="6"
          sm="2"
          md="2"
          lg="2"
          xl="1"
          class="  py-0 my-0  mb-2"
        >
          <Button
            color="primary"
            @click="getSantralList"
          >
            Filtrele
          </Button>
        </v-col>
      </v-row>

      <v-row
        v-for="item in santralInfo"
        :key="item.santral.id"
      >
        <Card
          :elevation="2"
          class="mx-5 my-5"
          width="99%"
        >
          <v-card-title class="blue lighten-5 mb-4">
            <div class="ml-4">
              <strong>Santral:</strong> {{ item.santral.santral }}  /
            </div>
            <div class="ml-4">
              <strong>Kurulu Güç:</strong> {{ item.santral.NominalPlantPower.value }} {{ item.santral.NominalPlantPower.unit }}  /
            </div>
            <div class="ml-4">
              <strong>Günlük Enerji:</strong> {{ item.santral.DailyEnergy }} kW  /
            </div>
            <div class="ml-4">
              <strong>Anlık Güç:</strong> {{ item.santral.IstantaneousPower }} kW   /
            </div>
            <div class="ml-4">
              <strong>Performans:</strong> % {{ item.santral.PerformanceRatio }}
            </div>
          </v-card-title>
          <v-card-text>
            <v-row
              v-for="datalogger in item.Dataloggers"
              :key="datalogger.id"
            >
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                xl="2"
                class="text-left "
              >
                <v-hover
                  v-slot="{hover}"
                  close-delay="200"
                >
                  <Card
                    :elevation="hover ? 16 : 2"
                    :class="{'on-hover': hover}"
                    class="py-2 px-3 light-blue lighten-5"
                  >
                    <div v-html="setDataloggerSvg('black')" />
                    <div
                      style="font-size:15px;color:black "
                      class="font-weight-black subtitle1"
                      v-html="' Veri kaydedici  ' "
                    />
                    <div
                      style="font-size:15px;color:black "
                      class="font-weight-black subtitle1"
                      v-html="'Aygıt Adı: ' + datalogger.deviceName + ' '"
                    />
                    <div
                      style="font-size:15px;color:black "
                      class="font-weight-black subtitle1"
                      v-html="'Marka: ' + datalogger.DeviceManufacture + ' '"
                    />
                    <div
                      style="font-size:15px;color:black "
                      class="font-weight-black subtitle1"
                      v-html="'Model: ' + datalogger.DeviceModel + ' '"
                    />
                  </Card>
                </v-hover>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="8"
                lg="9"
                xl="10"
              >
                <v-row>
                  <div
                    v-for="inverter in item.INVERTER"
                    :key="inverter.id"
                  >
                    <v-tooltip
                      right
                      dark
                      color="white"
                      :style="{opacity: 1}"
                    >
                      <template v-slot:activator="{on, attrs}">
                        <Card
                          class="mx-2 my-2 px-2 py-2  blue lighten-4"
                          :attrs="attrs"
                          width="280"
                          :on="on"
                        >
                          <div v-html="setInverterSvg(inverter)" />
                          <div>  {{ inverter.INVERTERNAME }}</div>
                          <div
                            style="font-size:14px;color:black "
                            class=" "
                            v-html="'AC Güç : ' + inverterText(inverter,'ACPower')"
                          />
                          <div
                            style="font-size:15px;color:black "
                            class=" "
                            v-html="'Günlük Enerji : ' + inverterText(inverter,'DailyEnergy')"
                          />
                        </Card>
                      </template>
                      <Card
                        class="px-3 py-3 blue lighten-4"
                        width="280"
                      >
                        <div v-html="setInverterSvg(inverter)" />
                        <div
                          style="font-size:15px;color:black "
                          class="font-weight-black subtitle1"
                          v-html="'Adı: ' + inverter.INVERTERNAME + ' '"
                        />
                        <div
                          style="font-size:15px;color:black "
                          class="font-weight-black subtitle1"
                          v-html="'Adı: ' + inverter.INVERTER + ' '"
                        />
                        <div
                          style="font-size:15px;color:black "
                          class="font-weight-black subtitle1"
                          v-html="'Marka: ' + inverter.inverterManufacture + ' '"
                        />
                        <div
                          style="font-size:15px;color:black "
                          class="font-weight-black subtitle1"
                          v-html="'Model: ' + inverter.inverterDeviceModel + ' '"
                        />
                        <div
                          style="font-size:15px;color:black "
                          class="font-weight-black subtitle1"
                          v-html="'Seri numara: ' + inverter.inverterDeviceSerial + ' '"
                        />
                        <div
                          style="font-size:15px;color:black "
                          class="font-weight-black subtitle1"
                          v-html="'Inverter Gücü: ' + inverter.installedDcPower + ' kW'"
                        />

                        <div
                          style="font-size:15px;color:black "
                          class=" font-weight-black subtitle1"
                          v-html="'AC Güç : ' + inverterText(inverter,'ACPower')"
                        />
                        <div
                          style="font-size:15px;color:black "
                          class=" font-weight-black subtitle1"
                          v-html="'DC Güç : ' + inverterText(inverter,'DCPower')"
                        />
                        <div

                          style="font-size:15px;color:black "
                          class=" font-weight-black subtitle1"
                          v-html="'Performans : ' + inverterText(inverter,'PerformanceRatio')"
                        />
                        <div

                          style="font-size:15px;color:black "
                          class=" font-weight-black subtitle1"
                          v-html="'Teorik Güç : ' + inverterText(inverter,'TheoreticalPower')"
                        />

                        <div
                          style="font-size:15px;color:black "
                          class="font-weight-black subtitle1"
                          v-html="'Günlük Enerji : ' + inverterText(inverter,'DailyEnergy')"
                        />

                        <div
                          style="font-size:15px;color:black "
                          class="font-weight-black subtitle1"
                          v-html="'Verimlilik: ' + inverterText(inverter,'Efficiency')"
                        />

                        <div
                          style="font-size:15px;color:black "
                          class="font-weight-black subtitle1"
                          v-html="'Sıçaklık: ' + inverter.TempBoard + ' °C'"
                        />
                        <div
                          style="font-size:15px;color:black "
                          class="font-weight-black subtitle1"
                          v-html="'Modül Sayısı: ' + inverter.ModuleQuantity + '  '"
                        />
                      </Card>
                    </v-tooltip>
                  </div>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </Card>
      </v-row>
    </v-card>
  </v-col>
</template>
<script>
import moment from 'moment';
import helper from '@/api/helper';
import {
  getSantraLastData,
} from '@/api/Request/santral';
import {
  getInverterDataIndex,
}
  from '@/api/Request/inverter';

import common from '@/api/component/common';

const inverterSvg = `
<svg
                                version="1.0"
                                xmlns="http://www.w3.org/2000/svg"
                                width="15.000000pt"
                                height="15.000000pt"
                                viewBox="0 0 150.000000 200.000000"
                                preserveAspectRatio="xMidYMid meet"
                              >

                                <g
                                  transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
                                  fill="inverter.color"
                                  stroke="none"
                                >
                                  <path
                                    d="M412 1870 c-42 -10 -78 -44 -92 -86 l-12 -34 -94 0 -94 0 0 -28 c0
-21 7 -32 25 -40 38 -17 52 -55 32 -86 -9 -13 -26 -27 -37 -31 -14 -4 -20 -15
-20 -36 l0 -29 95 0 95 0 0 -500 0 -500 -95 0 -95 0 0 -28 c0 -21 7 -32 25
-40 38 -17 52 -55 32 -86 -9 -13 -26 -27 -37 -31 -14 -4 -20 -15 -20 -36 l0
-29 93 0 93 0 19 -42 c11 -23 33 -51 48 -63 28 -20 40 -20 627 -20 587 0 599
0 627 20 15 12 37 40 48 63 l19 42 88 0 88 0 0 26 c0 16 -12 37 -30 54 -38 35
-38 55 0 90 18 17 30 38 30 54 l0 26 -90 0 -90 0 0 500 0 500 90 0 90 0 0 26
c0 16 -12 37 -30 54 -17 15 -30 36 -30 45 0 9 14 30 30 45 18 17 30 38 30 54
l0 26 -88 0 -88 0 -19 42 c-11 23 -33 51 -48 63 -28 20 -41 20 -605 22 -317 1
-592 -2 -610 -7z m-102 -245 l0 -65 -32 0 -33 0 0 65 0 65 33 0 32 0 0 -65z
m1190 -90 l0 -155 -500 0 -500 0 0 155 0 155 500 0 500 0 0 -155z m255 90 l0
-65 -32 0 -33 0 0 65 0 65 33 0 32 0 0 -65z m-748 -531 c51 -43 92 -82 90 -88
-3 -7 -26 -10 -62 -8 -145 8 -249 -102 -216 -228 11 -39 10 -46 -5 -57 -30
-22 -41 -15 -54 32 -33 116 30 249 138 294 36 15 39 29 17 90 -8 22 -12 41 -8
41 3 0 48 -34 100 -76z m239 -241 c17 -112 -42 -221 -145 -267 l-39 -18 18
-59 18 -59 -26 19 c-68 49 -167 138 -167 151 0 12 14 14 71 12 57 -3 79 0 112
17 75 38 114 124 93 206 -7 26 -11 48 -9 50 2 1 12 7 23 13 26 15 42 -5 51
-65z m-936 -478 l0 -65 -32 0 -33 0 0 65 0 65 33 0 32 0 0 -65z m1445 0 l0
-65 -32 0 -33 0 0 65 0 65 33 0 32 0 0 -65z"
                                  />
                                </g>
                              </svg>

`;
const dataloggerSvg = ` <svg
                          version="1.0"
                          xmlns="http://www.w3.org/2000/svg"
                          width="40px"
                          height="40px"
                          viewBox="0 0 512 512"
                          preserveAspectRatio="xMidYMid meet"
                        >

                          <g
                            transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                            fill="datalogger.color"
                            stroke="none"
                          >
                            <path
                              d="M171 4897 c-19 -7 -44 -25 -55 -40 l-21 -28 0 -1820 0 -1819 33 -32
32 -33 184 -3 184 -3 4 -117 c4 -94 9 -128 28 -170 27 -62 104 -137 160 -153
l38 -12 4 -126 c4 -142 16 -177 85 -243 66 -64 121 -82 248 -82 129 0 182 18
253 87 62 61 82 122 82 256 l0 108 39 12 c56 16 134 90 161 152 17 41 23 79
27 171 l6 118 167 0 168 0 4 -127 c3 -120 5 -132 33 -182 33 -60 80 -103 141
-128 l41 -17 5 -116 c7 -171 50 -250 166 -307 52 -26 67 -28 172 -28 105 0
120 2 172 28 116 57 159 136 166 307 l5 116 41 17 c61 25 108 68 141 128 28
50 30 62 33 182 l4 127 168 0 167 0 6 -118 c4 -92 10 -130 27 -171 27 -62 105
-136 161 -152 l39 -12 0 -108 c0 -134 20 -195 82 -256 71 -69 124 -87 253 -87
127 0 182 18 248 82 69 66 81 101 85 243 l4 126 38 12 c56 16 133 91 160 153
19 42 24 76 28 170 l4 118 172 0 c189 0 206 5 243 59 l23 34 0 1794 c0 1955 3
1841 -56 1880 -27 17 -121 18 -2399 20 -1821 2 -2378 -1 -2404 -10z m4619
-1882 l0 -1655 -2230 0 -2230 0 0 1655 0 1655 2230 0 2230 0 0 -1655z m-3370
-1986 c0 -141 30 -129 -323 -129 -222 0 -306 3 -315 12 -8 8 -12 47 -12 110
l0 98 325 0 325 0 0 -91z m1460 -7 c0 -63 -4 -102 -12 -110 -17 -17 -599 -17
-616 0 -8 8 -12 47 -12 110 l0 98 320 0 320 0 0 -98z m1470 0 c0 -63 -4 -102
-12 -110 -9 -9 -93 -12 -315 -12 -353 0 -323 -12 -323 129 l0 91 325 0 325 0
0 -98z m-3160 -455 c0 -59 -4 -97 -12 -105 -7 -7 -41 -12 -84 -12 -92 0 -98 7
-102 122 l-4 88 101 0 101 0 0 -93z m1470 4 c0 -113 -6 -121 -100 -121 -94 0
-100 8 -100 121 l0 89 100 0 100 0 0 -89z m1468 1 c-4 -115 -10 -122 -102
-122 -43 0 -77 5 -84 12 -8 8 -12 46 -12 105 l0 93 101 0 101 0 -4 -88z"
                            />
                            <path
                              d="M1025 4588 c-57 -45 -55 6 -55 -1178 0 -1201 -3 -1140 62 -1180 33
-20 46 -20 1528 -20 1482 0 1495 0 1528 20 65 40 62 -21 62 1180 0 1184 2
1133 -55 1178 l-28 22 -1507 0 -1507 0 -28 -22z m2885 -633 l0 -425 -210 0
c-161 0 -217 -3 -238 -14 -15 -8 -108 -109 -206 -225 -99 -116 -183 -208 -187
-204 -4 5 -165 211 -358 458 -194 248 -364 458 -379 467 -36 23 -90 23 -127
-2 -16 -10 -140 -123 -275 -249 l-245 -231 -237 0 -238 0 0 425 0 425 1350 0
1350 0 0 -425z m-1281 -690 c194 -247 364 -458 379 -467 29 -19 93 -24 121 -8
10 5 112 120 227 255 l209 245 172 0 173 0 0 -420 0 -420 -1350 0 -1350 0 0
420 0 420 265 0 c204 0 273 3 298 14 18 7 133 109 257 225 174 163 227 208
236 198 7 -6 170 -214 363 -462z"
                            />
                            <path
                              d="M1194 2109 c-100 -24 -205 -120 -239 -218 -22 -64 -19 -169 5 -235
28 -73 99 -147 175 -183 50 -23 73 -28 140 -28 102 0 178 33 247 106 66 70 91
140 86 244 -4 90 -25 145 -83 213 -71 82 -220 128 -331 101z m144 -254 c39
-33 48 -74 27 -118 -29 -63 -104 -76 -157 -28 -27 24 -33 36 -33 71 0 35 6 47
33 71 42 37 89 39 130 4z"
                            />
                            <path
                              d="M2045 2107 c-109 -37 -177 -95 -222 -192 -58 -127 -26 -282 78 -380
72 -67 126 -88 229 -89 71 0 94 4 141 26 72 33 134 94 168 167 22 47 26 70 26
141 0 102 -27 170 -94 237 -85 84 -226 123 -326 90z m156 -256 c23 -23 29 -38
29 -71 0 -117 -167 -139 -196 -26 -25 100 94 169 167 97z"
                            />
                            <path
                              d="M2898 2106 c-90 -24 -174 -97 -217 -186 -22 -46 -26 -69 -26 -140 0
-103 29 -174 97 -241 69 -67 136 -94 238 -93 103 1 157 22 229 89 191 179 114
494 -139 569 -63 19 -117 19 -182 2z m157 -250 c67 -67 27 -176 -65 -176 -33
0 -48 6 -71 29 -64 65 -20 171 71 171 30 0 47 -6 65 -24z"
                            />
                            <path
                              d="M3764 2109 c-100 -24 -205 -120 -239 -218 -8 -25 -15 -75 -15 -112 0
-135 66 -241 188 -301 59 -29 76 -33 147 -33 71 0 88 4 147 33 73 36 124 87
160 161 19 38 23 63 23 141 0 107 -16 153 -80 228 -71 82 -220 128 -331 101z
m148 -258 c27 -24 33 -36 33 -71 0 -105 -145 -138 -190 -43 -21 44 -12 85 27
118 41 35 88 33 130 -4z"
                            />
                          </g>
                        </svg>`;
console.log(common);
export default {
  data() {
    return {
      invIcon: '',
      santrals: [],
      Dataloggers: [],
      inverters: [],
      date: moment().format('YYYY-MM-DD'),
      hourmodel: moment().format('HH:mm'),
      santralModel: [],
      menu: false,
      modal: false,
      componentKey: 1,
      hourmenu: false,

      time: null,
      menu2: false,
      hourmodal2: false,
      step_time: 5,
      santralInfo: [],
      inverterData: [],

    };
  },
  computed: {
    santralList() {
      const { santralRawData } = this.$store.getters;
      if (helper.is_Empty(santralRawData)) return;
      const santralList = [];

      santralRawData.forEach((item) => {
        santralList.push({
          text: item.santral,
          value: item.santralCode,
        });
      });

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.santralModel = santralList[0].value;
      // eslint-disable-next-line consistent-return
      return santralList;
    },
    santralData() {
      const { santralRawData } = this.$store.getters;
      if (helper.is_Empty(santralRawData)) return;
      const santralData = {};
      santralRawData.forEach((item) => {
        santralData[item.santralCode] = item;
      });
      // eslint-disable-next-line consistent-return
      return santralData;
    },

  },
  async mounted() {
    // eslint-disable-next-line no-use-before-define
    const myVar1 = setInterval(myTimer1, 1000);

    const self = this;
    function myTimer1() {
      // santralRawData
      const {
        santralStringboxData,
        santralInverterData,
        santralDataList,
        santralRawData,
        santralDataloggerData,
      } = self.$store.getters;

      if (Object.keys(santralStringboxData).length > 0 && Object.keys(santralRawData).length > 0
        && Object.keys(santralDataloggerData).length > 0
      ) {
        const santralInfo = {};

        Object.keys(santralDataList).forEach((i) => {
          const { santralCode } = santralRawData[i];

          if (santralInfo[santralCode] === undefined) {
            santralInfo[santralCode] = {
              INVERTER: [],
              STRINGBOX: [],
              Dataloggers: [],
              santral: santralRawData[i],
            };
          }
          if (santralInverterData[santralCode]) {
            santralInfo[santralCode].INVERTER = santralInverterData[santralCode];
          }
          if (santralInverterData[santralCode]) {
            santralInfo[santralCode].STRINGBOX = santralStringboxData[santralCode];
          }
          if (santralDataloggerData[santralCode]) {
            santralInfo[santralCode].Dataloggers = santralDataloggerData[santralCode];
          }
        });

        self.santralInfo = santralInfo;
        self.stepTime = 5;
        clearInterval(myVar1);
        self.getSantralList();
      }
    }
  },
  methods: {
    allowedMinutes: (v) => v >= 10 && v <= 50,
    allowedStep: (m) => m % 10 === 0,
    inverterText(inverter, key) {
      const item = inverter.general_measure_list.filter((i) => i.value === key);

      let val = 0;
      if (!helper.is_Empty(inverter[key])) {
        val = inverter[key].toFixed(1);
      }
      let unit = '';
      if (!helper.is_Empty(item)) {
        unit = item[0].unit;
      }
      return `<span class="font-weight-black subtitle1">${val} ${unit}</span>`;
    },
    setInverterSvg(inverter) {
      let color = 'red';
      if (!helper.is_Empty(inverter.CommStatusCode)) {
        if (inverter.CommStatusCode > 0) {
          color = 'green';
        }
      }
      return inverterSvg.replace('inverter.color', color);
    },
    setDataloggerSvg(color) {
      return dataloggerSvg.replace('datalogger.color', color);
    },
    async getSantralList() {
      Object.keys(this.santralInfo).forEach((item) => {
        this.getLastSantral(this.santralInfo[item].santral, item);
        this.getInverterList(this.santralInfo[item], item);
      });
    },
    getLastSantral(item, code) {
      const payload = {
        condiniton: {
          santral: item.santral,
          skip: 0,
          limit: 1,
          orderBys: [
            {
              fieldName: 'datetimestamp',
              sortOrder: 'desc',
            },
          ],
          count: '0',
          measures: [
            'PerformanceRatio',
            'IstantaneousPower',
            'DailyEnergy',
          ],
          date: this.date,
        },

      };
      getSantraLastData(payload).then((res) => {
        const {
          PerformanceRatio,
          IstantaneousPower,
          DailyEnergy,
        } = res.data.data[0];
        this.santralInfo[code].santral.PerformanceRatio = parseFloat(PerformanceRatio).toFixed(1);
        this.santralInfo[code].santral.IstantaneousPower = parseFloat(IstantaneousPower).toFixed(1);
        this.santralInfo[code].santral.DailyEnergy = parseFloat(DailyEnergy).toFixed(1);
      });
    },
    async getInverterList(item, code) {
      try {
        const payload = {

          condiniton: {
            santral: item.santral.santral,
            Time: this.date,
            hour: this.hourmodel,
            limit: 100,
            orderBys: [
              {
                fieldName: 'datetimestamp',
                sortOrder: 'desc',
              },
            ],
            column: [
              'INVERTERNAME',
              'INVERTER',
              'inverterManufacture',
              'inverterDeviceModel',
              'inverterStatus',
              'Serial',
              'TheoreticalPower',
              'PerformanceRatio',
              'Efficiency',
              'ACPower',
              'DCPower',
              'TempBoard',
              'LifeTime',
              'DailyEnergy',
              'TotalEnergy',
              'CommStatusCode',
            ],
          },

        };

        getInverterDataIndex(payload).then((res) => {
          const temp = res.data.data;
          Object.keys(temp).forEach((i) => {
            if (this.santralInfo[code].INVERTER[temp[i].INVERTER]) {
              this.santralInfo[code].INVERTER[temp[i].INVERTER] = {
                ...this.santralInfo[code].INVERTER[temp[i].INVERTER],
                ...temp[i],
              };
            }
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};

</script>

<style scoped>
.v-tooltip__content.menuable__content__active {
  opacity: 1!important;
}
</style>
